.logo {
    display: flex;
    line-height: 3rem;
}

@media (max-width: 1255px) {
    .logo {
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.logo h1 {
    text-align: left;
    font-size: 3rem !important;
    color: #fff;
    margin-bottom: 25px;
}

.logo span {
    color: var(--blue);
    font-size: 3rem;
}
