.page-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container {
    padding: 0 20px;
    width: 1000px;
    display: flex;
    flex-direction: column;
}

.image-logo {
    width: 400px
}

.headline {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headline-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.headline-container h1 {
    color: #33333B;
    font-size: 25px;
    font-weight: 900;
}

h1 span {
    font-size: 20px;
    margin-left: 3px;
    color: #0897E9;
}

@media (max-width: 495px) {
    h1 span {
        width: 100%;
    }
}

#span-price {
    text-decoration: line-through;
}

.headline h2 {
    background-color: #ffb341;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
    margin: 10px 0;
}

.headline h3 {
    color: #33333B;
    font-weight: 400;
    font-size: 17px;
    text-align: center;
}

.ebook-form-container {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ebook-container {
    position: relative;
}

.visual-element {
    top: 0;
    right: -100px;
    width: 500px;
    height: 150px;
    background: linear-gradient(to left, #0897E9, #63bef3);
    position: absolute;
    z-index: -1;
}

.visual-element-ebook {
    bottom: 110px;
    left: 130px;
    width: 100px;
    height: 50px;
    border: 2px solid #0897E9;
    position: absolute;
    z-index: -1;
}

.ebook-image {
    width: 550px;
}

.form-container {
    width: 400px;
    margin-top: 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
}

.form-container h3 {
    color: #33333B;
    font-size: 20px;
    text-align: center;
    margin: 20px 0 5px 0;
    font-weight: 400;
}

.form {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.field-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.field-group label {
    font-size: 17px;
    color: #33333B;
}

.field-group input, select {
    width: 100%;
    height: 45px;
    padding-left: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    /* FONT */
    font-size: 17px;
    color: #33333B;
}

.error {
    color: red;
}

.btn-form {
    margin: 20px 0;
    padding: 12px 30px;
    background: #0897E9;
    border-radius: 5px;
    border: 1.5px solid #0897E9;
    /* FONT */
    color: #fff;
    font-size: 17px;
    font-weight: 700;
   
    cursor: pointer;
    transition: .5s;
}

.btn-form:hover {
    background: transparent;
    color: #0897E9;
}

.visual-element-form, .visual-element-form-top {
    bottom: -20px;
    right: -20px;
    width: 80px;
    height: 40px;
    border: 5px solid #ffb341;
    position: absolute;
    z-index: -1;
}

.visual-element-form-top {
    top: -20px !important;
    border: 2px solid #ffb341;
}

@media (max-width: 640px) {
    .ebook-image {
        width: 320px;
    }
    .visual-element {
        width: 380px ;
        right: -50px;
    }
}

@media (max-width: 450px) {
    .form-container {
        width: 95%;
    }
    .visual-element {
        width: 300px;
        right: 0;
    }
    .visual-element-form, .visual-element-form-top {
        right: 0
    }
}

@media (max-width: 400px) {
    .image-logo {
        width: 100%;
    }
}